<script>
import BaseForm from './ActivityBaseForm.vue';
import Land from '../../services/land';
const land = new Land();

import { playFrogSound } from '../../utils/sound';
import Orientation from '../../services/orientation';
import Geolocation from '../../services/geolocation';
export default {
  extends: BaseForm,
  methods: {
    async submit() {
      this.loading = true;
      const type = this.formData.type;

      const data = {
        geocoordinates: Geolocation.validatedData(this.$store.state.gps),
        orientation: Orientation.validatedData(this.$store.state.orientation),
        createdBy: this.$store.state?.user?.uid || null,
        ...this.formData
      };

      land
        .addActivity(this.parcelId, data)
        .then(() => {
          this.reset();
          const action = {
            feature: 'land',
            action: 'create',
            detail: {
              ...data
            }
          };
          this.$emit('success', action);
          playFrogSound();
        })
        .catch(error => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>
