import { Howl } from 'howler';
const palySound = file => {
  var audio = new Howl({
    src: file
  });
  audio.play();
};

const playFrogSound = () => {
  const frog = require('@/assets/audio/frog.mp3');
  palySound(frog);
};

const playCashSound = () => {
  const audio = require('@/assets/audio/cash.mp3');
  palySound(audio);
};

export { palySound, playFrogSound, playCashSound };
